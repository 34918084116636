import { api } from "@/api-client";

class DocumentTypeTranslator {
    translateFromDocumentType(type: api.DocumentType) {
        switch (type) {
            case api.DocumentType.Other:
                return "Muu";
            case api.DocumentType.Reroute:
                return "Ümbersõit";
        }
    }

    translateFromString(type: keyof typeof api.DocumentType): string {
        return this.translateFromDocumentType(api.DocumentType[type]);
    }
}

const documentTypeTranslator = new DocumentTypeTranslator();
export default documentTypeTranslator;
