import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_SMALL, TABLE_PAGE_SIZE_OPTIONS_SMALL } from "@/shared/constants";
import { LocationQuery } from "vue-router";

export interface IPaginationWithSorting<TSortBy> extends IPagination {
    sortBy?: TSortBy | string | null;
    descending?: boolean | undefined;

    toQuasarPagination(): {
        sortBy?: string | null | undefined;
        descending?: boolean | undefined;
        page?: number | undefined;
        rowsPerPage?: number | undefined;
        rowsNumber?: number | undefined;
    };
}

export interface IPagination {
    rowsPerPage?: number | undefined;
    page?: number | undefined;
    rowsNumber?: number | undefined; // Update value when executing server-side paging https://quasar.dev/vue-components/table/
}

export class ListFilters {
    removeAppliedFilters(filter: any, appliedFilters: any[]) {
        appliedFilters.splice(appliedFilters.indexOf(filter), 1);
    }
}

export abstract class ListFiltersWithPaging implements IPagination {
    rowsPerPage: number;
    page: number;
    rowsNumber?: number;

    protected constructor(data?: Partial<IPagination>) {
        this.rowsPerPage = data?.rowsPerPage ?? DEFAULT_PAGE_SIZE_SMALL;
        this.page = data?.page ?? 1;
        this.rowsNumber = data?.rowsNumber;
    }

    mapFromQuery(query: LocationQuery) {
        this.rowsPerPage = query.ridu != null && Number(query.ridu) !== DEFAULT_PAGE_SIZE_SMALL ? Number(query.ridu) : DEFAULT_PAGE_SIZE_SMALL;
        this.page = Number(query.leht ?? 1);
    }

    createQuery() {
        return {
            ridu: this.rowsPerPage != null && this.rowsPerPage !== DEFAULT_PAGE_SIZE_SMALL ? this.rowsPerPage : undefined,
            leht: this.page > 1 ? this.page : undefined,
        };
    }
}

export abstract class ListFiltersWithSortedPaging<TSortBy> extends ListFiltersWithPaging implements IPaginationWithSorting<TSortBy> {
    sortBy?: TSortBy;
    descending: boolean;

    protected constructor(data?: Partial<IPaginationWithSorting<TSortBy>>) {
        super(data);
        this.sortBy = data?.sortBy as TSortBy;
        // If sort by is not set, sorting will resort to default value and data should be in ascending order.
        this.descending = (data?.sortBy != undefined && data?.descending) ?? false;
    }

    mapFromQuery(query: LocationQuery) {
        super.mapFromQuery(query);
        this.sortBy = query.sorteeri ? (String(query.sorteeri) as TSortBy) : undefined;
        this.descending = query.kahanevalt != null ? query.kahanevalt !== "false" : false;
    }

    createQuery() {
        return {
            ...super.createQuery(),
            sorteeri: this.sortBy ? this.sortBy : undefined,
            kahanevalt: this.descending ? this.descending.toString() : undefined,
        };
    }

    toQuasarPagination(): {
        sortBy?: string | null | undefined;
        descending?: boolean | undefined;
        page?: number | undefined;
        rowsPerPage?: number | undefined;
        rowsNumber?: number | undefined;
    } {
        return {
            sortBy: this.sortBy?.toString(),
            descending: this.descending,
            rowsPerPage: this.rowsPerPage,
            page: this.page,
            rowsNumber: this.rowsNumber,
        };
    }
}
