import { IPaginationWithSorting, ListFiltersWithSortedPaging } from "@/components/ListFilters";
import { LocationQuery } from "vue-router";

export interface ITerminalDeparturesFilters extends IPaginationWithSorting<TerminalDeparturesColumns> {} // eslint-disable-line

export enum TerminalDeparturesColumns {
    Valjumine = "valjumine",
    Juht = "juht",
    Liin = "liin",
    Soiduk = "soiduk",
    Peatus = "peatus",
}

export class TerminalDeparturesFilters extends ListFiltersWithSortedPaging<any> {
    constructor(data?: Partial<ITerminalDeparturesFilters>) {
        super(data);
    }

    createQuery() {
        return super.createQuery();
    }

    mapFromQuery(query: LocationQuery) {
        super.mapFromQuery(query);
    }
}
