import { api } from "@/api-client";
import mobisInstanceTranslator from "@/features/MobisInstanceTranslator";
import workTypeTranslator from "@/features/WorkTypeTranslator";
import { enumKeys } from "@/helpers/Utils";

abstract class DelayMetricFilter {
    abstract label: string;
    abstract options: readonly string[];
    selectedOptions: string[] = [];

    removeOption(removedOption: string) {
        this.selectedOptions = this.selectedOptions.filter((x) => x !== removedOption);
    }

    clear() {
        this.selectedOptions = [];
    }
}

class DelayMetricMobisInstanceFilter extends DelayMetricFilter {
    label = "Sõidukiliik";
    mobisInstances: { mobisInstance: api.MobisInstance; label: string }[] = [];
    options: readonly string[] = [];

    constructor() {
        super();
        this.mobisInstances = enumKeys(api.MobisInstance).map((x) => ({
            label: mobisInstanceTranslator.translateStringToVehicleType(x),
            mobisInstance: api.MobisInstance[x],
        }));
        this.options = this.mobisInstances.map((x) => x.label);
    }

    toInputModel() {
        return this.selectedOptions.map((x) => this.mobisInstances.find((y) => y.label === x)?.mobisInstance).filter((x) => x != null);
    }
}

class DelayMetricWorkTypeFilter extends DelayMetricFilter {
    label = "Tööliik";
    workTypes: { workType: api.WorkType; label: string }[] = [];
    options: readonly string[] = [];

    constructor() {
        super();
        this.workTypes = Object.values(workTypeTranslator.all)
            .filter((x) => x != null)
            .map((x) => ({ label: workTypeTranslator.translate(x), workType: x }));
        this.options = this.workTypes.map((x) => x.label);
    }

    toInputModel() {
        return this.selectedOptions.map((x) => this.workTypes.find((y) => y.label === x)?.workType).filter((x) => x != null);
    }
}

class DelayMetricDriverDepartmentFilter extends DelayMetricFilter {
    label = "Osakond";
    options: readonly string[] = [];

    constructor(departments: string[]) {
        super();
        this.options = [...departments].sort();
    }

    toInputModel() {
        return this.selectedOptions;
    }
}

type DelayListFilter = {
    mobisInstances?: DelayMetricMobisInstanceFilter;
    workTypes?: DelayMetricWorkTypeFilter;
    departments?: DelayMetricDriverDepartmentFilter;
};

export class DelayListFilters {
    departments?: string[] = [];
    filters: DelayListFilter = {};

    getFilters() {
        return Object.values(this.filters);
    }

    constructor() {
        this.resetFilters();
    }

    resetFilters() {
        this.filters = {
            mobisInstances: new DelayMetricMobisInstanceFilter(),
            workTypes: new DelayMetricWorkTypeFilter(),
            departments: new DelayMetricDriverDepartmentFilter(this.departments ?? []),
        };
    }

    async init() {
        const client = new api.MetricsClient();
        this.departments = (await client.driverDepartments()).departments;
        this.filters.departments = new DelayMetricDriverDepartmentFilter(this.departments);
    }
}
