import { api } from "@/api-client";
import OperationModalTabs, { OperationModalTab } from "@/features/incidents/incidentView/shared/OperationModalTabs";

export class CreateLineChangeModel {
    endingJob?: api.IncidentJobModel;
    endingDriverAndVehicleId?: number;
    startingJob?: api.IncidentJobModel;
    startingDriverAndVehicleId?: number;
    hasLineWorkStarted = true;
    endingDeparture?: api.DepartureOperationLocationInputModel;
    startingLocation: api.DepartureOperationLocationInputModel | api.ManuallyAddedOperationLocationInputModel = new api.DepartureOperationLocationInputModel();
    redirections: api.IncidentOperationRedirectionInputModel[] = [];
    allowOverlappingVehicles = false;

    static from(operation: api.LineChangeOperationViewModel) {
        return {
            hasLineWorkStarted: operation.endingLocation != null,
            endingDeparture: operation.endingLocation != null ? api.DepartureOperationLocationInputModel.fromJS({ departureId: operation.endingLocation.departureId }) : undefined,
            startingLocation: operation.startingLocation.toInputModel(),
            redirections: operation.redirections.map((r) => r.toRedirectionInputModel()),
            allowOverlappingVehicles: false,
        };
    }
}

export default class LineChangeTabs extends OperationModalTabs {
    static ENDING_JOB_INDEX = 0;
    get ENDING_JOB(): OperationModalTab {
        return this.getTab(LineChangeTabs.ENDING_JOB_INDEX);
    }

    static STARTING_JOB_INDEX = 1;
    get STARTING_JOB(): OperationModalTab {
        return this.getTab(LineChangeTabs.STARTING_JOB_INDEX);
    }

    static HAS_LINE_WORK_STARTED_INDEX = 2;
    get HAS_LINE_WORK_STARTED(): OperationModalTab {
        return this.getTab(LineChangeTabs.HAS_LINE_WORK_STARTED_INDEX);
    }

    static ENDING_DEPARTURE_INDEX = 3;
    get ENDING_DEPARTURE(): OperationModalTab {
        return this.getTab(LineChangeTabs.ENDING_DEPARTURE_INDEX);
    }

    static STARTING_LOCATION_INDEX = 4;
    get STARTING_LOCATION(): OperationModalTab {
        return this.getTab(LineChangeTabs.STARTING_LOCATION_INDEX);
    }

    static REDIRECTIONS_INDEX = 5;
    get REDIRECTIONS(): OperationModalTab {
        return this.getTab(LineChangeTabs.REDIRECTIONS_INDEX);
    }

    static OVERVIEW_INDEX = 6;
    get OVERVIEW(): OperationModalTab {
        return this.getTab(LineChangeTabs.OVERVIEW_INDEX);
    }

    constructor(tabIndexes: number[], currentTabIndex: number) {
        const endingJobTab = new OperationModalTab(LineChangeTabs.ENDING_JOB_INDEX, (_: number, model: CreateLineChangeModel, validationErrors: api.ResultError) => {
            validationErrors.clear();

            if (model.endingJob?.jobId == null) {
                validationErrors.appendMessage("Töö on kohustuslik", "jobId");
            }

            if (model.endingJob?.jobId != null && model.endingDriverAndVehicleId == null) {
                validationErrors.appendMessage("Valitud tööl puudub juht.", "driverId");
            }

            return !validationErrors.hasMessages();
        });

        const startingJobTab = new OperationModalTab(LineChangeTabs.STARTING_JOB_INDEX, (_: number, model: CreateLineChangeModel, validationErrors: api.ResultError) => {
            validationErrors.clear();

            if (model.startingJob?.jobId == null) {
                validationErrors.appendMessage("Töö on kohustuslik", "jobId");
            }

            if (model.startingJob?.jobId != null && model.startingDriverAndVehicleId == null) {
                validationErrors.appendMessage("Valitud tööl puudub juht.", "driverId");
            }

            return !validationErrors.hasMessages();
        });

        const hasLineWorkStartedTab = new OperationModalTab(LineChangeTabs.HAS_LINE_WORK_STARTED_INDEX, () => true);

        const endingDepartureTab = new OperationModalTab(LineChangeTabs.ENDING_DEPARTURE_INDEX, (_: number, model: CreateLineChangeModel, validationErrors: api.ResultError) => {
            validationErrors.clear();

            if (model.hasLineWorkStarted && model.endingDeparture == null) {
                validationErrors.appendMessage("Lõpp-peatus on kohustuslik", "departureId");
            }

            return !validationErrors.hasMessages();
        });

        const startingLocationTab = new OperationModalTab(LineChangeTabs.STARTING_LOCATION_INDEX, (_: number, model: CreateLineChangeModel, validationErrors: api.ResultError) => {
            validationErrors.clear();

            model.startingLocation.validate(validationErrors);

            return !validationErrors.hasMessages();
        });

        const redirectionsTab = new OperationModalTab(LineChangeTabs.REDIRECTIONS_INDEX, async (incidentId: number, model: CreateLineChangeModel, validationErrors: api.ResultError) => {
            validationErrors.clear();
            await api.IncidentOperationRedirectionInputModel.validate(incidentId, model.redirections, validationErrors);
            return !validationErrors.hasMessages();
        });

        const overviewTab = new OperationModalTab(LineChangeTabs.OVERVIEW_INDEX, () => true);

        super(
            [endingJobTab, startingJobTab, hasLineWorkStartedTab, endingDepartureTab, startingLocationTab, redirectionsTab, overviewTab].filter((x) => tabIndexes.some((i) => i == x.index)),
            currentTabIndex
        );
    }
}
