import { LocationQuery } from "vue-router";
import { IPaginationWithSorting, ListFiltersWithSortedPaging } from "@/components/ListFilters";
import { api } from "@/api-client";

export interface IReservePendingDriversFilters extends IPaginationWithSorting<ReservePendingDriversColumns> {
    driverGroups: string[];
}

export enum ReservePendingDriversColumns {
    Driver = "juht",
    DriverGroup = "grupp",
    PlannedVehicle = "soiduk",
    Line = "liin",
    StopName = "asukoht",
    ShiftStart = "algus",
    ShiftEnd = "lopp",
    HealthInspection = "tervisekontroll",
    WorkType = "tooliik",
    ReplacementDriver = "asendatud_juht",
    ReplacementVehicle = "asendatud_soiduk",
}

export class ReservePendingDriversFilters extends ListFiltersWithSortedPaging<ReservePendingDriversColumns> implements IReservePendingDriversFilters {
    driverGroups: string[];
    static readonly STORAGE_KEY = "ReservePendingDriversListFilters";

    constructor(data?: Partial<IReservePendingDriversFilters>) {
        super(data);
        this.driverGroups = data?.driverGroups ?? [];
    }

    mapFromQuery(query: LocationQuery) {
        super.mapFromQuery(query);
        this.driverGroups = query.grupp ? String(query.grupp).split(",") : [];
    }

    createQuery() {
        return {
            ...super.createQuery(),
            grupp: this.driverGroups.length ? this.driverGroups : undefined,
        };
    }

    clearAllFilters() {
        sessionStorage.removeItem(ReservePendingDriversFilters.STORAGE_KEY);
        return new ReservePendingDriversFilters();
    }

    applyOn(drivers: api.PendingDriverViewModel[]): api.PendingDriverViewModel[] {
        drivers = this.filterByDriverGroups(drivers);
        return drivers;
    }

    filterByDriverGroups(jobs: api.PendingDriverViewModel[]) {
        return jobs.filter((job) => (this.driverGroups.length ? this.driverGroups.includes(job.pendingDriverAndVehicle?.driver.group ?? "") : true));
    }
}
