import { api } from "@/api-client";
import { LocationQuery } from "vue-router";
import { JobOrderVehicleTypeFilterOptions, WorkTypeFilterOptions } from "@/features/jobOrders/table/JobOrderFilters";
import { IPaginationWithSorting, ListFiltersWithSortedPaging } from "@/components/ListFilters";
import moment from "moment/moment";

abstract class IncidentFilterOptions {
    abstract options: string[];
}

// incident filters
// there is no label/value separation because the multi-select form doesn't support it
// instead we guarantee that each selection option string is unique and convert back from it
// upon creating the filter query for the API endpoint

export class IncidentStatusFilterOptions extends IncidentFilterOptions {
    options = ["Töös", "Lõpetatud", "Kinnitatud", "Automaatselt kinnitatud", "Kustutatud"];
    apiOptions = [
        api.FilterByIncidentStatus.InProgress,
        api.FilterByIncidentStatus.Completed,
        api.FilterByIncidentStatus.Confirmed,
        api.FilterByIncidentStatus.AutomaticallyConfirmed,
        api.FilterByIncidentStatus.Deleted,
    ];

    fromSelection(selectedOptions: string[]) {
        return [...Array(this.options.length).keys()].map((i) => (selectedOptions.includes(this.options[i]) ? this.apiOptions[i] : undefined)).filter((x) => x != null);
    }

    toOption(value: api.FilterByIncidentStatus) {
        return this.options[this.apiOptions.indexOf(value)];
    }
}

export class IncidentTypeGroupFilterOptions extends IncidentFilterOptions {
    options = [];
    incidentTypeGroups: api.IIncidentTypeGroupViewModel[];

    constructor(incidentTypeGroups: api.IIncidentTypeGroupViewModel[]) {
        super();
        this.incidentTypeGroups = incidentTypeGroups;
        // @ts-expect-error
        this.options = incidentTypeGroups.map((tg) => tg.name);
    }

    fromSelection(value: string[]) {
        const selected = this.incidentTypeGroups.filter((tg) => value.includes(tg.name));
        return selected.map((x) => x.id);
    }

    toOption(value: number) {
        return this.incidentTypeGroups.find((tg) => tg.id === value)?.name ?? "";
    }
}

export class IncidentTypeFilterOptions extends IncidentFilterOptions {
    options = [];
    incidentTypes: api.IIncidentTypeViewModel[];

    constructor(incidentTypeGroups: api.IIncidentTypeGroupViewModel[]) {
        super();
        this.incidentTypes = incidentTypeGroups.flatMap((tg) => tg.incidentTypes);
        // @ts-expect-error
        this.options = this.incidentTypes.map((t) => t.name).filter((name) => name !== "Muu juhtum");
    }

    fromSelection(selectedOptions: string[]) {
        const selected = this.incidentTypes.filter((t) => selectedOptions.includes(t.name));
        return selected.map((x) => x.id);
    }

    toOption(value: number) {
        return this.incidentTypes.find((t) => t.id === value)?.name ?? "";
    }
}

export class IncidentWorkLineNumberFilterOptions extends IncidentFilterOptions {
    options: string[] = [];

    constructor(lineNumbers: string[]) {
        super();
        this.options = lineNumbers;
    }

    fromSelection(selectedOptions: string[]) {
        return selectedOptions;
    }

    toOption(value: string) {
        return value;
    }
}

export class IncidentRegistrarFilterOptions extends IncidentFilterOptions implements IncidentFilterOptions {
    options = [];
    registrars: api.IRegistrarOption[] = [];

    constructor(registrars: api.IRegistrarOption[]) {
        super();
        this.registrars = registrars;

        // @ts-expect-error
        this.options = registrars.map((registrar) => this.format(registrar));
        // @ts-expect-error
        this.options.push("Tundmatu registreerija");
    }

    fromSelection(selectedOptions: string[]) {
        return selectedOptions.map((selected) => (selected === "Tundmatu registreerija" ? 0 : this.registrars.find((registrar) => this.format(registrar) == selected)?.id ?? 0));
    }

    format(registrar: api.IRegistrarOption) {
        return `${registrar.name} (#${registrar.employeeNumber?.padStart(4, "0")})`;
    }

    toOption(value: number) {
        if (value === 0) return "Tundmatu registreerija";
        const registrar = this.registrars.find((registrar) => registrar.id == value);
        // @ts-expect-error
        return this.format(registrar);
    }
}

export class IncidentDriverDepartmentsFilterOptions extends IncidentFilterOptions {
    options: string[] = [];

    constructor(departments: string[]) {
        super();
        this.options = departments;
    }

    fromSelection(selectedOptions: string[]) {
        return selectedOptions;
    }

    toOption(value: string) {
        return value;
    }
}

export class IncidentDriverGroupsFilterOptions extends IncidentFilterOptions {
    options: string[] = [];

    constructor(driverGroups: string[]) {
        super();
        this.options = driverGroups;
    }

    fromSelection(selectedOptions: string[]) {
        return selectedOptions;
    }

    toOption(value: string) {
        return value;
    }
}

export type IncidentFiltersOptions = {
    statuses: IncidentStatusFilterOptions;
    typeGroups: IncidentTypeGroupFilterOptions;
    types: IncidentTypeFilterOptions;
    workTypes: WorkTypeFilterOptions;
    lineNumbers: IncidentWorkLineNumberFilterOptions;
    vehicleTypes: JobOrderVehicleTypeFilterOptions;
    registrars: IncidentRegistrarFilterOptions;
    driverDepartments: IncidentDriverDepartmentsFilterOptions;
    driverGroups: IncidentDriverGroupsFilterOptions;
};

export interface IIncidentsListFilters extends IPaginationWithSorting<IncidentListColumns> {
    registrationDateAfter: Date | undefined;
    registrationDateBefore: Date | undefined;
    typeGroups: number[];
    types: number[];
    workTypes: api.WorkType[];
    lineNumbers: string[];
    vehicleTypes: api.VehicleTypeFilterItem[];
    registrars: number[];
    statuses: api.FilterByIncidentStatus[];
    driverDepartments: string[];
    driverGroups: string[];
    searchString: string;
}

export enum IncidentListColumns {
    IncidentId = "id",
    RegistrationTime = "registreerimiine",
    TypeGroup = "liik",
    Category = "tuup",
    LineNumber = "liin",
    Vehicle = "soiduk",
    Driver = "juht",
    DriverGroup = "grupp",
    Departments = "osakond",
}

export class IncidentsListFilters extends ListFiltersWithSortedPaging<IncidentListColumns> implements IIncidentsListFilters {
    registrationDateAfter: Date | undefined;
    registrationDateBefore: Date | undefined;
    typeGroups: number[];
    types: number[];
    workTypes: api.WorkType[];
    lineNumbers: string[];
    vehicleTypes: api.VehicleTypeFilterItem[];
    registrars: number[];
    statuses: api.FilterByIncidentStatus[];
    driverDepartments: string[];
    driverGroups: string[];
    searchString: string;

    toApiSort(): api.SortIncidentsBy {
        switch (this.sortBy) {
            // Expected to not match api SortBy
            case IncidentListColumns.IncidentId:
                return api.SortIncidentsBy.RegistrationTime;
            case IncidentListColumns.RegistrationTime:
                return api.SortIncidentsBy.RegistrationTime;
            case IncidentListColumns.TypeGroup:
                return api.SortIncidentsBy.TypeGroup;
            case IncidentListColumns.Category:
                return api.SortIncidentsBy.Category;
            case IncidentListColumns.LineNumber:
                return api.SortIncidentsBy.LineNumber;
            case IncidentListColumns.Vehicle:
                return api.SortIncidentsBy.VehicleId;
            case IncidentListColumns.Driver:
                return api.SortIncidentsBy.DriverName;
            default:
                return api.SortIncidentsBy.RegistrationTime;
        }
    }

    static readonly STORAGE_KEY = "IncidentListFilters";

    constructor(data?: Partial<IIncidentsListFilters>) {
        super(data);
        this.registrationDateAfter = data?.registrationDateAfter;
        this.registrationDateBefore = data?.registrationDateBefore;
        this.typeGroups = data?.typeGroups ?? [];
        this.types = data?.types ?? [];
        this.workTypes = data?.workTypes ?? [];
        this.lineNumbers = data?.lineNumbers ?? [];
        this.vehicleTypes = data?.vehicleTypes ?? [];
        this.registrars = data?.registrars ?? [];
        this.statuses = data?.statuses ?? [];
        this.driverDepartments = data?.driverDepartments ?? [];
        this.driverGroups = data?.driverGroups ?? [];
        this.searchString = data?.searchString ?? "";
    }

    mapFromQueryWithOptions(query: LocationQuery, options: IncidentFiltersOptions) {
        super.mapFromQuery(query);
        this.registrationDateAfter = query.alates && moment(query.alates?.toString(), "DD.MM.YYYY").isValid() ? moment(query.alates?.toString(), "DD.MM.YYYY").toDate() : undefined;
        this.registrationDateBefore = query.kuni && moment(query.kuni?.toString(), "DD.MM.YYYY").isValid() ? moment(query.kuni?.toString(), "DD.MM.YYYY").toDate() : undefined;

        this.typeGroups = query.liik ? options.typeGroups.fromSelection(String(query.liik).split(",")) : [];
        this.types = query.alamliik ? options.types.fromSelection(String(query.alamliik).split(",")) : [];
        this.workTypes = query.tooliik ? WorkTypeFilterOptions.fromOptions(String(query.tooliik).split(",")) : [];
        this.lineNumbers = query.liin ? options.lineNumbers.fromSelection(String(query.liin).split(",")) : [];
        this.vehicleTypes = query.soidukiliik ? JobOrderVehicleTypeFilterOptions.fromOptions(String(query.soidukiliik).split(",")) : [];
        this.registrars = query.registreerija ? options.registrars.fromSelection(String(query.registreerija).split(",")) : [];
        this.statuses = query.olek ? options.statuses.fromSelection(String(query.olek).split(",")) : [];
        this.driverDepartments = query.osakond ? options.driverDepartments.fromSelection(String(query.osakond).split(",")) : [];
        this.driverGroups = query.grupp ? options.driverGroups.fromSelection(String(query.grupp).split(",")) : [];
        this.searchString = (query.otsing as string) ?? "";
    }

    createQueryWithOptions(filterOptions: IncidentFiltersOptions) {
        return {
            ...super.createQuery(),
            alates: moment(this.registrationDateAfter, "DD.MM.YYYY").isValid() ? moment(this.registrationDateAfter, "DD.MM.YYYY").format("DD.MM.YYYY") : undefined,
            kuni: moment(this.registrationDateBefore, "DD.MM.YYYY").isValid() ? moment(this.registrationDateBefore, "DD.MM.YYYY").format("DD.MM.YYYY") : undefined,
            liik: this.typeGroups.length ? this.typeGroups.map((value) => filterOptions.typeGroups.toOption(value)) : undefined,
            alamliik: this.types.length ? this.types.map((value) => filterOptions.types.toOption(value)) : undefined,
            tooliik: this.workTypes.length ? this.workTypes.map((value) => WorkTypeFilterOptions.toOption(value)) : undefined,
            liin: this.lineNumbers.length ? this.lineNumbers.map((value) => filterOptions.lineNumbers.toOption(value)) : undefined,
            soidukiliik: this.vehicleTypes.length ? this.vehicleTypes.map((value) => JobOrderVehicleTypeFilterOptions.toOption(value)) : undefined,
            registreerija: this.registrars.length ? this.registrars.map((value) => filterOptions.registrars.toOption(value)) : undefined,
            olek: this.statuses.length ? this.statuses.map((value) => filterOptions.statuses.toOption(value)) : undefined,
            osakond: this.driverDepartments.length ? this.driverDepartments.map((value) => filterOptions.driverDepartments.toOption(value)) : undefined,
            grupp: this.driverGroups.length ? this.driverGroups.map((value) => filterOptions.driverGroups.toOption(value)) : undefined,
            otsing: this.searchString !== "" ? this.searchString : undefined,
        };
    }

    clearAllFilters() {
        sessionStorage.removeItem(IncidentsListFilters.STORAGE_KEY);
        return new IncidentsListFilters();
    }
}
