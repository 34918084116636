import { api } from "@/api-client";

class MobisInstanceTranslator {
    translateToVehicleCategory(instance: api.MobisInstance) {
        return {
            [api.MobisInstance.Bus]: api.VehicleCategory.Bus,
            [api.MobisInstance.BusSpecialTransport]: api.VehicleCategory.SocialTransit,
            [api.MobisInstance.Tram]: api.VehicleCategory.Tram,
            [api.MobisInstance.Trolleybus]: api.VehicleCategory.Trolleybus,
        }[instance];
    }

    translateToVehicleType(instance: api.MobisInstance) {
        switch (instance) {
            case api.MobisInstance.Bus:
                return "Buss";
            case api.MobisInstance.Tram:
                return "Tramm";
            case api.MobisInstance.Trolleybus:
                return "Troll";
            case api.MobisInstance.BusSpecialTransport:
                return "Erivedu";
        }
    }

    translateStringToVehicleType(instance: keyof typeof api.MobisInstance): string {
        return this.translateToVehicleType(api.MobisInstance[instance]);
    }
}

const mobisInstanceTranslator = new MobisInstanceTranslator();
export default mobisInstanceTranslator;
