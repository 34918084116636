import { api } from "@/api-client";

class HealthInspectionResultTranslator {
    all() {
        return [
            { description: "Korras", value: api.HealthInspectionResultClassifier.Passed },
            { description: "Jääknähud - ootel", value: api.HealthInspectionResultClassifier.PendingWithResiduals },
            { description: "Korduvpuhumine - jääknähud", value: api.HealthInspectionResultClassifier.RepeatedTesting },
            { description: "Joove - töölt kõrvaldatud", value: api.HealthInspectionResultClassifier.Intoxicated },
            { description: "Korras - Tehniline rike", value: api.HealthInspectionResultClassifier.PassedWithTechnicalMalfunction },
            { description: "Korras - käsitsi", value: api.HealthInspectionResultClassifier.PassedManually },
            { description: "Muu tervisliku seisundi häire/muutus töölt kõrvaldamisega", value: api.HealthInspectionResultClassifier.RemovalFromWork },
            { description: 'Muu tervisliku seisundi häire/muutus "ootel"', value: api.HealthInspectionResultClassifier.PendingWithOtherHealthDisorder },
            { description: "Terviserike töökohustuste täitmisel", value: api.HealthInspectionResultClassifier.HealthDisorderOnDuty },
        ];
    }

    translate(value?: api.HealthInspectionResultClassifier) {
        return this.all().find((x) => x.value == value)?.description ?? "Puudub";
    }
}

const healthInspectionResultTranslator = new HealthInspectionResultTranslator();
export default healthInspectionResultTranslator;
