import { IJobsMonitoringCommonFilters, JobsMonitoringCommonFilters } from "@/features/jobsMonitoring/JobsMonitoringCommonFilters";
import { api } from "@/api-client";
import { formatTime } from "@/helpers/DateFormatter";
import { LocationQuery } from "vue-router";

export enum EndedJobsListColumns {
    PlannedDepartureTime = "valjus",
    ExpectedGarageArrivalTime = "saabus",
    Driver = "juht",
    DriverGroup = "grupp",
    Vehicle = "soiduk",
    Line = "liin",
    TechnicalCondition = "tehnoseisund",
    WorkType = "tooliik",
}

export interface IEndedJobsTableFilters extends IJobsMonitoringCommonFilters<EndedJobsListColumns> {
    expectedGarageArrivalTimeFrom: string | undefined;
    expectedGarageArrivalTimeTo: string | undefined;
}

export type EndedJobsListFilterOptions = {
    lineNumberFilterOptions: string[];
    vehicleTypeFilterOptions: string[];
    workTypeFilterOptions: string[];
    driverGroupsFilterOptions: string[];
};

export class EndedJobsListFilters extends JobsMonitoringCommonFilters<EndedJobsListColumns> {
    expectedGarageArrivalTimeFrom: string | undefined;
    expectedGarageArrivalTimeTo: string | undefined;

    static STORAGE_KEY = "EndedJobsTableFilters";

    constructor(data?: Partial<IEndedJobsTableFilters>) {
        super(data);
        this.expectedGarageArrivalTimeFrom = data?.expectedGarageArrivalTimeFrom;
        this.expectedGarageArrivalTimeTo = data?.expectedGarageArrivalTimeTo;
    }

    createQuery() {
        return {
            ...super.createQuery(),
            saabus_alates: this.expectedGarageArrivalTimeFrom ? this.expectedGarageArrivalTimeFrom : undefined,
            saabus_kuni: this.expectedGarageArrivalTimeTo ? this.expectedGarageArrivalTimeTo : undefined,
        };
    }

    mapFromQuery(query: LocationQuery) {
        super.mapFromQuery(query);
        this.expectedGarageArrivalTimeFrom = query.saabus_alates ? (query.saabus_alates as string) : undefined;
        this.expectedGarageArrivalTimeTo = query.saabus_kuni ? (query.saabus_kuni as string) : undefined;
    }

    clearAllFilters() {
        sessionStorage.removeItem(EndedJobsListFilters.STORAGE_KEY);
        return new EndedJobsListFilters();
    }

    applyOn(viewModels: api.MonitoredJobViewModel[]): api.MonitoredJobViewModel[] {
        return viewModels
            .filter((vm) => super.applyCommonFiltersOn(vm))
            .filter((vm) => (this.expectedGarageArrivalTimeFrom && vm.job.expectedGarageArrivalTime ? formatTime(vm.job.expectedGarageArrivalTime) >= this.expectedGarageArrivalTimeFrom : true))
            .filter((vm) => (this.expectedGarageArrivalTimeTo && vm.job.expectedGarageArrivalTime ? formatTime(vm.job.expectedGarageArrivalTime) < this.expectedGarageArrivalTimeTo : true));
    }
}
