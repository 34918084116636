import { IJobsMonitoringCommonFilters, JobsMonitoringCommonFilters } from "@/features/jobsMonitoring/JobsMonitoringCommonFilters";
import { IMonitoredJobAndVehicleLocationSnapshotModel } from "@/features/jobsMonitoring/MonitoredJobAndVehicleLocationSnapshotModel";
import { LocationQuery } from "vue-router";

export enum InProgressListColumns {
    PlannedDepartureTime = "valjub",
    ExpectedGarageArrivalTime = "saabub",
    Deviation = "halve",
    StopName = "asukoht",
    Driver = "juht",
    DriverDepartmentAndGroup = "osakond_grupp",
    Vehicle = "soiduk",
    Line = "liin",
    HealthInspection = "tervisekontroll",
    WorkType = "tooliik",
}

export interface IInProgressListFilters extends IJobsMonitoringCommonFilters<InProgressListColumns> {
    deviationInMinutes?: number;
    deviationFilterType?: "vaiksem" | "suurem";
    healthInspectionNotPassed: "true" | "false" | undefined;
}

export type InProgressListFilterOptions = {
    lineNumberFilterOptions: string[];
    vehicleTypeFilterOptions: string[];
    locationFilterOptions: string[];
    workTypeFilterOptions: string[];
    departmentFilterOptions: string[];
    driverGroupsFilterOptions: string[];
};

export class InProgressListFilters extends JobsMonitoringCommonFilters<InProgressListColumns> {
    deviationInMinutes?: number;
    deviationFilterType?: "vaiksem" | "suurem";
    healthInspectionNotPassed: "true" | "false" | undefined = undefined;

    static STORAGE_KEY = "InProgressTableFilters";

    constructor(data?: Partial<IInProgressListFilters>) {
        super(data);
        this.deviationInMinutes = data?.deviationInMinutes;
        this.deviationFilterType = data?.deviationFilterType;
        this.healthInspectionNotPassed = data?.healthInspectionNotPassed;
    }

    createQuery() {
        return {
            ...super.createQuery(),
            halve: this.deviationInMinutes ? this.deviationInMinutes : undefined,
            halve_liik: this.deviationFilterType ? this.deviationFilterType : undefined,
            tervisekontroll_labimata: this.healthInspectionNotPassed ? this.healthInspectionNotPassed : undefined,
        };
    }
    mapFromQuery(query: LocationQuery) {
        super.mapFromQuery(query);
        this.deviationInMinutes = query.halve ? Number(query.halve) : undefined;
        this.deviationFilterType = query.halve_liik ? (query.halve_liik as "vaiksem" | "suurem") : undefined;
        this.healthInspectionNotPassed = query.tervisekontroll_labimata ? (query.tervisekontroll_labimata as "true" | "false") : undefined;
    }

    clearAllFilters() {
        sessionStorage.removeItem(InProgressListFilters.STORAGE_KEY);
        return new InProgressListFilters();
    }

    applyOn(viewModels: IMonitoredJobAndVehicleLocationSnapshotModel[]): IMonitoredJobAndVehicleLocationSnapshotModel[] {
        return viewModels
            .filter((vm) => super.applyCommonFiltersOn(vm.monitoredJob))
            .filter((vm) =>
                this.healthInspectionNotPassed === "true"
                    ? vm.monitoredJob.healthInspection == null || !vm.monitoredJob.healthInspection.hasPositiveTestResult
                    : this.healthInspectionNotPassed === "false"
                    ? vm.monitoredJob.healthInspection != null && vm.monitoredJob.healthInspection.hasPositiveTestResult
                    : true
            )
            .filter(
                (vm) =>
                    this.deviationInMinutes == undefined ||
                    this.deviationFilterType == undefined ||
                    (this.deviationFilterType == "vaiksem"
                        ? // @ts-expect-error
                          vm.vehicleLocationSnapshot?.deviation?.deviationInMinutes < this.deviationInMinutes
                        : this.deviationFilterType == "suurem"
                        ? // @ts-expect-error
                          vm.vehicleLocationSnapshot?.deviation?.deviationInMinutes > this.deviationInMinutes
                        : true)
            )
            .filter((vm) => (this.locations.length ? this.locations.includes(vm?.vehicleLocationSnapshot?.currentLocation ?? JobsMonitoringCommonFilters.NO_LOCATION) : true));
    }
}
