import script from "./FilterDeviation.vue?vue&type=script&setup=true&lang=ts"
export * from "./FilterDeviation.vue?vue&type=script&setup=true&lang=ts"

import "./FilterDeviation.vue?vue&type=style&index=0&id=7207794c&lang=scss"

const __exports__ = script;

export default __exports__
import QBtnDropdown from 'quasar/src/components/btn-dropdown/QBtnDropdown.js';
import QRadio from 'quasar/src/components/radio/QRadio.js';
import QItem from 'quasar/src/components/item/QItem.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtnDropdown,QRadio,QItem});
