import { IPagination, ListFiltersWithPaging } from "@/components/ListFilters";
import { api } from "@/api-client";
import documentTypeTranslator from "@/features/DocumentTypeTranslator";
import mobisInstanceTranslator from "@/features/MobisInstanceTranslator";
import { LocationQuery } from "vue-router";

export interface IReroutesListFilters extends IPagination {
    searchString: string | undefined;
    transportTypes: string[];
    documentTypes: string[];
}

export class ReroutesListFilters extends ListFiltersWithPaging implements IReroutesListFilters {
    searchString: string | undefined = undefined;
    transportTypes: string[] = [];
    documentTypes: string[] = [];

    constructor(data?: Partial<IReroutesListFilters>) {
        super(data);
        this.searchString = data?.searchString ?? undefined;
        this.transportTypes = data?.transportTypes ?? [];
        this.documentTypes = data?.documentTypes ?? [];
    }

    mapFromQuery(query: LocationQuery) {
        super.mapFromQuery(query);
        this.transportTypes = query.soidukiliik ? String(query.soidukiliik).split(",") : [];
        this.documentTypes = query.dokumendiliik ? String(query.dokumendiliik).split(",") : [];
        this.searchString = query.otsing ? String(query.otsing) : undefined;
    }

    createQuery() {
        return {
            ...super.createQuery(),
            otsing: this.searchString?.length ? this.searchString : undefined,
            soidukiliik: this.transportTypes.length ? this.transportTypes : undefined,
            dokumendiliik: this.documentTypes.length ? this.documentTypes : undefined,
        };
    }

    clearAllFilters() {
        return new ReroutesListFilters();
    }

    applyOn(reroutes: api.RerouteListViewModel[]): api.RerouteListViewModel[] {
        return (
            reroutes
                .filter((reroute) => filterByTransportType(this.transportTypes, reroute))
                .filter((reroute) => filterByDocumentType(this.documentTypes, reroute))
                // @ts-expect-error
                .filter((reroute) => filterBySearchString(this.searchString, reroute))
        );

        function filterByTransportType(transportTypes: string[], reroute: api.RerouteListViewModel): boolean {
            if (!transportTypes.length) {
                return true;
            }
            return transportTypes.includes(mobisInstanceTranslator.translateToVehicleType(reroute.transportType));
        }

        function filterByDocumentType(documentTypes: string[], reroute: api.RerouteListViewModel): boolean {
            if (!documentTypes.length) {
                return true;
            }
            return documentTypes.includes(documentTypeTranslator.translateFromDocumentType(reroute.documentType));
        }

        function filterBySearchString(searchString: string, reroute: api.RerouteListViewModel): boolean {
            if (!searchString) {
                return true;
            }
            return reroute.fileName.toLowerCase().includes(searchString.toLowerCase()) || reroute.lineNumbers.some((ln) => ln.lineNumber.toLowerCase().includes(searchString.toLowerCase()));
        }
    }
}
