import { IPaginationWithSorting, ListFiltersWithSortedPaging } from "@/components/ListFilters";
import { LocationQuery } from "vue-router";

export interface ITerminalArrivalsFilters extends IPaginationWithSorting<TerminalArrivalsColumns> {
    showEarlierDepartures: boolean;
}

export enum TerminalArrivalsColumns {
    Saabub = "saabub",
    Liin = "liin",
    Regnr = "regnr",
    Garaažnr = "garaažnr",
    Peatus = "peatus",
}

export class TerminalArrivalsFilters extends ListFiltersWithSortedPaging<any> {
    constructor(data?: Partial<ITerminalArrivalsFilters>) {
        super(data);
    }

    createQuery() {
        return { ...super.createQuery() };
    }

    mapFromQuery(query: LocationQuery) {
        super.mapFromQuery(query);
    }
}
