import OperationModalTabs, { OperationModalTab } from "@/features/incidents/incidentView/shared/OperationModalTabs";
import { api } from "@/api-client";

export default class ReserveToJobAssignmentOperationTabs extends OperationModalTabs {
    static JOB_INDEX = 0;
    get JOB(): OperationModalTab {
        return this.getTab(ReserveToJobAssignmentOperationTabs.JOB_INDEX);
    }

    static RESERVE_JOB_INDEX = 1;
    get RESERVE_JOB(): OperationModalTab {
        return this.getTab(ReserveToJobAssignmentOperationTabs.RESERVE_JOB_INDEX);
    }

    static VEHICLE_GOING_ON_LINE_INDEX = 2;
    get VEHICLE_GOING_ON_LINE(): OperationModalTab {
        return this.getTab(ReserveToJobAssignmentOperationTabs.VEHICLE_GOING_ON_LINE_INDEX);
    }

    static RESERVE_STARTING_POINT_INDEX = 3;
    get RESERVE_STARTING_POINT(): OperationModalTab {
        return this.getTab(ReserveToJobAssignmentOperationTabs.RESERVE_STARTING_POINT_INDEX);
    }

    static PREPARATION_STARTS_AT_INDEX = 4;
    get PREPARATION_STARTS_AT(): OperationModalTab {
        return this.getTab(ReserveToJobAssignmentOperationTabs.PREPARATION_STARTS_AT_INDEX);
    }

    static REDIRECTIONS_INDEX = 5;
    get REDIRECTIONS(): OperationModalTab {
        return this.getTab(ReserveToJobAssignmentOperationTabs.REDIRECTIONS_INDEX);
    }

    static OVERVIEW_INDEX = 6;
    get OVERVIEW(): OperationModalTab {
        return this.getTab(ReserveToJobAssignmentOperationTabs.OVERVIEW_INDEX);
    }

    constructor(tabIndexes: number[], currentTabIndex: number) {
        const jobTab = new OperationModalTab(ReserveToJobAssignmentOperationTabs.JOB_INDEX, (_: number, model: api.AssignReserveToJobInputModel, validationErrors: api.ResultError) => {
            validationErrors.clear();

            model.validateJob(validationErrors);

            return !validationErrors.hasMessages();
        });

        const reserveJobTab = new OperationModalTab(ReserveToJobAssignmentOperationTabs.RESERVE_JOB_INDEX, (_: number, model: api.AssignReserveToJobInputModel, validationErrors: api.ResultError) => {
            validationErrors.clear();

            model.reserveJob.validate(validationErrors);

            return !validationErrors.hasMessages();
        });

        const vehicleGoingOnLineTab = new OperationModalTab(
            ReserveToJobAssignmentOperationTabs.VEHICLE_GOING_ON_LINE_INDEX,
            (_: number, model: api.AssignReserveToJobInputModel, validationErrors: api.ResultError) => {
                validationErrors.clear();

                if (model.vehicleGoingOnLineId == null) {
                    validationErrors.appendMessage("Sõiduki valik on kohustuslik", "vehicleId");
                }

                return !validationErrors.hasMessages();
            }
        );

        const driverStartingPointTab = new OperationModalTab(
            ReserveToJobAssignmentOperationTabs.RESERVE_STARTING_POINT_INDEX,
            (_: number, model: api.AssignReserveToJobInputModel, validationErrors: api.ResultError) => {
                validationErrors.clear();

                if (model.driverStartingPoint == null) {
                    validationErrors.appendMessage("Juhi alguspunkt on kohustuslik", "driverStartingPoint");
                }

                model.driverStartingPoint?.validate(validationErrors);

                return !validationErrors.hasMessages();
            }
        );

        const preparationStartsAtTab = new OperationModalTab(ReserveToJobAssignmentOperationTabs.PREPARATION_STARTS_AT_INDEX, () => true);

        const redirectionsTab = new OperationModalTab(
            ReserveToJobAssignmentOperationTabs.REDIRECTIONS_INDEX,
            async (incidentId: number, model: api.AssignReserveToJobInputModel, validationErrors: api.ResultError) => {
                validationErrors.clear();

                await api.IncidentOperationRedirectionInputModel.validate(incidentId, model.redirections, validationErrors);

                return !validationErrors.hasMessages();
            }
        );

        const overviewTab = new OperationModalTab(ReserveToJobAssignmentOperationTabs.OVERVIEW_INDEX, () => true);

        super(
            [jobTab, reserveJobTab, vehicleGoingOnLineTab, driverStartingPointTab, preparationStartsAtTab, redirectionsTab, overviewTab].filter((x) => tabIndexes.some((i) => i == x.index)),
            currentTabIndex
        );
    }
}
