import workTypeTranslator from "@/features/WorkTypeTranslator";
import { formatTime } from "@/helpers/DateFormatter";
import { api } from "@/api-client";
import { IPaginationWithSorting, ListFiltersWithSortedPaging } from "@/components/ListFilters";
import { LocationQuery } from "vue-router";

export interface IJobsMonitoringCommonFilters<TColumns> extends IPaginationWithSorting<TColumns> {
    searchString: string | undefined;
    lineNumbers: string[];
    vehicleTypes: string[];
    locations: string[];
    workTypes: string[];
    departments: string[];
    driverGroups: string[];
    plannedDepartureTimeFrom: string | undefined;
    plannedDepartureTimeTo: string | undefined;
}

export abstract class JobsMonitoringCommonFilters<TColumns> extends ListFiltersWithSortedPaging<TColumns> implements IJobsMonitoringCommonFilters<TColumns> {
    static readonly NO_VEHICLE = "Sõiduk puudub";
    static readonly NO_LOCATION = "Tundmatu asukoht";

    searchString: string | undefined = undefined;
    lineNumbers: string[] = [];
    vehicleTypes: string[] = [];
    locations: string[] = [];
    workTypes: string[] = [];
    departments: string[] = [];
    driverGroups: string[] = [];
    plannedDepartureTimeFrom: string | undefined;
    plannedDepartureTimeTo: string | undefined;

    protected constructor(data?: Partial<IJobsMonitoringCommonFilters<TColumns>>) {
        super(data);
        this.searchString = data?.searchString ?? undefined;
        this.lineNumbers = data?.lineNumbers ?? [];
        this.vehicleTypes = data?.vehicleTypes ?? [];
        this.locations = data?.locations ?? [];
        this.workTypes = data?.workTypes ?? [];
        this.departments = data?.departments ?? [];
        this.driverGroups = data?.driverGroups ?? [];
        this.plannedDepartureTimeFrom = data?.plannedDepartureTimeFrom ?? undefined;
        this.plannedDepartureTimeTo = data?.plannedDepartureTimeTo ?? undefined;
    }

    createQuery() {
        return {
            ...super.createQuery(),
            otsing: this.searchString?.length ? this.searchString : undefined,
            liin: this.lineNumbers?.length ? this.lineNumbers : undefined,
            soidukiliik: this.vehicleTypes?.length ? this.vehicleTypes : undefined,
            asukoht: this.locations?.length ? this.locations : undefined,
            tooliik: this.workTypes?.length ? this.workTypes : undefined,
            osakond: this.departments?.length ? this.departments : undefined,
            grupp: this.driverGroups?.length ? this.driverGroups : undefined,
            valjus_alates: this.plannedDepartureTimeFrom ? this.plannedDepartureTimeFrom : undefined,
            valjus_kuni: this.plannedDepartureTimeTo ? this.plannedDepartureTimeTo : undefined,
        };
    }

    mapFromQuery(query: LocationQuery) {
        super.mapFromQuery(query);
        this.searchString = query.otsing ? String(query.otsing) : undefined;
        this.lineNumbers = query.liin ? String(query.liin).split(",") : [];
        this.vehicleTypes = query.soidukiliik ? String(query.soidukiliik).split(",") : [];
        this.locations = query.asukoht ? String(query.asukoht).split(",") : [];
        this.workTypes = query.tooliik ? String(query.tooliik).split(",") : [];
        this.departments = query.osakond ? String(query.osakond).split(",") : [];
        this.driverGroups = query.grupp ? String(query.grupp).split(",") : [];
        this.plannedDepartureTimeFrom = query.valjus_alates ? (query.valjus_alates as string) : undefined;
        this.plannedDepartureTimeTo = query.valjus_kuni ? (query.valjus_kuni as string) : undefined;
    }

    applyCommonFiltersOn(viewModel: api.IMonitoredJobViewModel): boolean {
        if (this.lineNumbers.length && !this.lineNumbers.some((lineNumber) => viewModel.job.lineNumbers.includes(lineNumber))) {
            return false;
        }

        if (this.vehicleTypes.length && !this.vehicleTypes.includes(viewModel.vehicle?.type.label() ?? JobsMonitoringCommonFilters.NO_VEHICLE)) {
            return false;
        }

        if (this.workTypes.length && !this.workTypes.includes(workTypeTranslator.translate(viewModel.job.workType))) {
            return false;
        }

        if (this.departments.length && !this.departments.includes(viewModel.driver.department)) {
            return false;
        }

        if (this.driverGroups.length && !this.driverGroups.includes(viewModel.driver.group)) {
            return false;
        }

        if (this.plannedDepartureTimeFrom && viewModel.job.plannedDepartureTime && formatTime(viewModel.job.plannedDepartureTime) < this.plannedDepartureTimeFrom) {
            return false;
        }

        if (this.plannedDepartureTimeTo && viewModel.job.plannedDepartureTime && formatTime(viewModel.job.plannedDepartureTime) >= this.plannedDepartureTimeTo) {
            return false;
        }

        if (
            this.searchString?.length &&
            !viewModel.driver.name.toLowerCase().includes(this.searchString.toLowerCase()) &&
            !viewModel.driver.tableNumber.toLowerCase().includes(this.searchString.toLowerCase()) &&
            !viewModel.vehicle?.garageNumber?.toLowerCase().includes(this.searchString.toLowerCase())
        ) {
            return false;
        }

        return true;
    }
}
