import { LocationQuery } from "vue-router";
import { IPaginationWithSorting, ListFiltersWithSortedPaging } from "@/components/ListFilters";
import { api } from "@/api-client";
import moment from "moment-timezone";

export interface IDeparturesListFilters<TColumns> extends IPaginationWithSorting<TColumns> {
    searchString: string | undefined;
    vehicleTypes: string[];
    stopNames: string[];
    plannedDepartureFrom: string | undefined;
    plannedDepartureTo: string | undefined;
    driverDepartment: string[];
    driverGroups: string[];
}

export abstract class DeparturesListFilters<TColumns> extends ListFiltersWithSortedPaging<TColumns> implements IDeparturesListFilters<TColumns> {
    searchString: string | undefined = undefined;
    vehicleTypes: string[] = [];
    stopNames: string[] = [];
    plannedDepartureFrom: string | undefined;
    plannedDepartureTo: string | undefined;
    driverDepartment: string[] = [];
    driverGroups: string[] = [];

    static readonly NO_VEHICLE = "Sõiduk puudub";

    protected constructor(data?: Partial<IDeparturesListFilters<TColumns>>) {
        super(data);
        this.searchString = data?.searchString ?? undefined;
        this.vehicleTypes = data?.vehicleTypes ?? [];
        this.stopNames = data?.stopNames ?? [];
        this.plannedDepartureFrom = data?.plannedDepartureFrom ?? undefined;
        this.plannedDepartureTo = data?.plannedDepartureTo ?? undefined;
        this.driverDepartment = data?.driverDepartment ?? [];
        this.driverGroups = data?.driverGroups ?? [];
    }

    mapFromQuery(query: LocationQuery) {
        super.mapFromQuery(query);
        this.searchString = query.otsing ? String(query.otsing) : undefined;
        this.vehicleTypes = query.soidukiliik ? String(query.soidukiliik).split(",") : [];
        this.stopNames = query.asukoht ? String(query.asukoht).split(",") : [];
        this.plannedDepartureFrom = query.alates ? (query.alates as string) : undefined;
        this.plannedDepartureTo = query.kuni ? (query.kuni as string) : undefined;
        this.driverDepartment = query.osakond ? String(query.osakond).split(",") : [];
        this.driverGroups = query.grupp ? String(query.grupp).split(",") : [];
    }

    createQuery() {
        return {
            ...super.createQuery(),
            otsing: this.searchString?.length ? this.searchString : undefined,
            soidukiliik: this.vehicleTypes.length ? this.vehicleTypes : undefined,
            asukoht: this.stopNames.length ? this.stopNames : undefined,
            alates: this.plannedDepartureFrom ? this.plannedDepartureFrom : undefined,
            kuni: this.plannedDepartureTo ? this.plannedDepartureTo : undefined,
            osakond: this.driverDepartment.length ? this.driverDepartment : undefined,
            grupp: this.driverGroups.length ? this.driverGroups : undefined,
        };
    }

    applyOn(departures: api.IDepartureViewModel[]): api.IDepartureViewModel[] {
        departures = this.filterByPlannedDeparture(departures);

        return departures
            .filter((departure) =>
                this.vehicleTypes.length
                    ? this.vehicleTypes.includes(
                          departure.servingDriverAndVehicle.vehicle == null || departure.servingDriverAndVehicle.vehicle?.type == null
                              ? DeparturesListFilters.NO_VEHICLE
                              : departure.servingDriverAndVehicle.vehicle.type.label()
                      )
                    : true
            )
            .filter((departure) => (this.stopNames.length ? this.stopNames.includes(departure.stop?.name ?? "") : true))
            .filter((departure) => (this.driverDepartment.length ? this.driverDepartment.includes(departure.servingDriverAndVehicle.driver.department ?? "") : true))
            .filter((departure) => (this.driverGroups.length ? this.driverGroups.includes(departure.servingDriverAndVehicle.driver.group ?? "") : true))
            .filter((departure) =>
                this.searchString?.length != null && this.searchString.length >= 1
                    ? departure.servingDriverAndVehicle.driver.name.toLowerCase().includes(this.searchString.toLowerCase()) ||
                      departure.servingDriverAndVehicle.vehicle?.garageNumber?.toLowerCase().includes(this.searchString.toLowerCase()) ||
                      departure.servingDriverAndVehicle.driver.tableNumber.toLowerCase().includes(this.searchString.toLowerCase())
                    : true
            );
    }

    filterByPlannedDeparture(departures: api.IDepartureViewModel[]): api.IDepartureViewModel[] {
        const filterFrom = this.plannedDepartureFrom != null && moment(this.plannedDepartureFrom, "HH:mm").isValid() ? moment(this.plannedDepartureFrom, "HH:mm") : undefined;
        let filterTo = this.plannedDepartureTo != null && moment(this.plannedDepartureTo, "HH:mm").isValid() ? moment(this.plannedDepartureTo, "HH:mm") : undefined;

        if (filterFrom != null && filterTo != null && filterTo < filterFrom) {
            filterTo = filterTo.add(1, "day"); // tomorrow night
        }

        return departures
            .filter((departure) => (filterFrom != null ? moment(departure.plannedDeparture) >= filterFrom : true))
            .filter((job) => (filterTo != null ? moment(job.plannedDeparture) <= filterTo : true));
    }
}
