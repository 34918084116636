import { LocationQuery } from "vue-router";
import { api } from "@/api-client";
import ReserveDriverViewModel = api.ReserveDriverViewModel;
import workTypeTranslator from "@/features/WorkTypeTranslator";
import { IPaginationWithSorting, ListFiltersWithSortedPaging } from "@/components/ListFilters";
import HasReserveJobInfoViewModel = api.HasReserveJobInfoViewModel;

export interface IReserveDriverListFilters extends IPaginationWithSorting<ReserveDriverListColumns> {
    searchString: string | undefined;
    departments: string[];
    driverGroups: string[];
    competencies: string[];
    workTypes: string[];
}

export enum ReserveDriverListColumns {
    NameAndTableNumber = "nimi",
    Phone = "telefon",
    DepartmentAndGroup = "osakond_grupp",
    Competencies = "kompetentsid",
}

export class ReserveDriversListFilters extends ListFiltersWithSortedPaging<ReserveDriverListColumns> implements IReserveDriverListFilters {
    searchString: string | undefined = undefined;
    departments: string[] = [];
    driverGroups: string[] = [];
    competencies: string[] = [];
    workTypes: string[] = [];

    static readonly NO_COMPETENCIES = "Kompetentsid puuduvad";
    static readonly NO_WORK_TYPE = "Töö puudub";

    static readonly STORAGE_KEY = "ReserveDriversListFilters";

    constructor(data?: Partial<IReserveDriverListFilters>) {
        super(data);
        this.searchString = data?.searchString;
        this.departments = data?.departments ?? [];
        this.driverGroups = data?.driverGroups ?? [];
        this.competencies = data?.competencies ?? [];
        this.workTypes = data?.workTypes ?? [];
    }

    mapFromQuery(query: LocationQuery) {
        super.mapFromQuery(query);
        this.searchString = query.otsing ? String(query.otsing) : undefined;
        this.departments = query.osakond ? String(query.osakond).split(",") : [];
        this.driverGroups = query.grupp ? String(query.grupp).split(",") : [];
        this.competencies = query.kompetentsid ? String(query.kompetentsid).split(",") : [];
        this.workTypes = query.tooliik ? String(query.tooliik).split(",") : [];
    }

    createQuery() {
        return {
            ...super.createQuery(),
            otsing: this.searchString?.length ? this.searchString : undefined,
            osakond: this.departments.length ? this.departments : undefined,
            grupp: this.driverGroups.length ? this.driverGroups : undefined,
            kompetentsid: this.competencies.length ? this.competencies : undefined,
            tooliik: this.workTypes.length ? this.workTypes : undefined,
        };
    }

    clearAllFilters() {
        sessionStorage.removeItem(ReserveDriversListFilters.STORAGE_KEY);
        return new ReserveDriversListFilters();
    }

    applyOn(drivers: Array<ReserveDriverViewModel>): Array<ReserveDriverViewModel> {
        return drivers
            .filter((model) => filterByWorkType(this.workTypes, model))
            .filter((driver) => filterByDepartment(this.departments, driver))
            .filter((driver) => filterByDriverGroups(this.driverGroups, driver))
            .filter((driver) => filterByCompetence(this.competencies, driver))
            .filter((driver) => filterBySearchString(this.searchString?.toLowerCase(), driver));

        function filterByDepartment(departments: string[], driver: ReserveDriverViewModel): boolean {
            if (!departments.length) {
                return true;
            }
            return driver.department != null && departments.includes(driver.department);
        }

        function filterByDriverGroups(driverGroups: string[], driver: ReserveDriverViewModel): boolean {
            if (!driverGroups.length) {
                return true;
            }
            return driver.group != null && driverGroups.includes(driver.group);
        }

        function filterByCompetence(competencies: string[], driver: ReserveDriverViewModel): boolean {
            if (!competencies.length) {
                return true;
            }
            const driverCompetencies = driver.competencies.length ? driver.competencies.map((c) => c.name) : [];

            return driverCompetencies.length ? driverCompetencies.some((c) => competencies.includes(c)) : competencies.includes(ReserveDriversListFilters.NO_COMPETENCIES);
        }

        function filterBySearchString(searchString: string | undefined, driver: ReserveDriverViewModel): boolean {
            if (!searchString?.length) {
                return true;
            }
            return driver.fullName.toLowerCase().includes(searchString) || driver.tableNumber.toLowerCase().includes(searchString);
        }

        function filterByWorkType(workTypes: string[], model: HasReserveJobInfoViewModel): boolean {
            if (!workTypes.length) {
                return true;
            }
            return model.jobs.length ? model.jobs.some((j) => workTypes.includes(workTypeTranslator.translate(j.workType))) : workTypes.includes(ReserveDriversListFilters.NO_WORK_TYPE);
        }
    }
}
