import { DeparturesListFilters, IDeparturesListFilters } from "@/features/preparation/departures/DeparturesListFilters";

export enum ReserveDeparturesListColumns {
    PlannedDeparture = "valjumine",
    Driver = "juht",
    DriverDepartmentAndGroup = "osakond/grupp",
    Vehicle = "soiduk",
    EntranceInspection = "labipaas",
    HealthInspection = "tervisekontroll",
    ExitInspection = "liinile_valjumine",
    StopName = "asukoht",
    WorkType = "tooliik",
    TechnicalCondition = "tehnoseisund",
}

export class ReserveDeparturesListFilters extends DeparturesListFilters<ReserveDeparturesListColumns> {
    static readonly STORAGE_KEY = "ReserveDeparturesListFilters";

    constructor(data?: Partial<IDeparturesListFilters<ReserveDeparturesListColumns>>) {
        super(data);
    }

    clearAllFilters() {
        sessionStorage.removeItem(ReserveDeparturesListFilters.STORAGE_KEY);
        return new ReserveDeparturesListFilters({ sortBy: ReserveDeparturesListColumns.PlannedDeparture });
    }
}
