import { IPaginationWithSorting, ListFiltersWithSortedPaging } from "@/components/ListFilters";
import { LocationQuery } from "vue-router";
import { api } from "@/api-client";
import workTypeTranslator from "@/features/WorkTypeTranslator";
import moment from "moment-timezone";

export interface IReservesListFilters extends IPaginationWithSorting<ReservesListColumns> {
    searchString: string;
    driverGroups: string[];
    vehicleTypes: string[];
    workTypes: string[];
    jobCompletionTypes: string[];
    shiftFrom?: Date;
    shiftTo?: Date;

    clearAllFilters(clearSearch: boolean): ReservesListFilters;
}

export enum ReservesListColumns {
    Driver = "juht",
    DriverGroup = "grupp",
    Vehicle = "soiduk",
    Line = "liin",
    ReplacedLine = "asendatud_liin",
    StopName = "asukoht",
    PreparationStart = "alates",
    AfterShiftPreparationEnd = "kuni",
    ReserveTimes = "liin_reservis",
    HealthInspection = "tervisekontroll",
    WorkType = "tooliik",
}

export class ReservesListFilters extends ListFiltersWithSortedPaging<ReservesListColumns> implements IReservesListFilters {
    searchString = "";
    driverGroups: string[] = [];
    vehicleTypes: string[] = [];
    workTypes: string[] = [];
    jobCompletionTypes: string[];
    shiftFrom: Date | undefined;
    shiftTo: Date | undefined;

    static NoVehicle = "Sõiduk puudub";
    static NoWorkType = "Tööliik puudub";

    static readonly STORAGE_KEY = "ReservesListFilters";

    static readonly JOB_COMPLETION_OPTIONS = ["Lõppenud", "Töös", "Alustab tulevikus"] as const;

    constructor(data?: Partial<IReservesListFilters>) {
        super(data);
        this.searchString = data?.searchString ?? "";
        this.driverGroups = data?.driverGroups ?? [];
        this.vehicleTypes = data?.vehicleTypes ?? [];
        this.workTypes = data?.workTypes ?? [];
        this.jobCompletionTypes = data?.jobCompletionTypes ?? [];
        this.shiftFrom = data?.shiftFrom;
        this.shiftTo = data?.shiftTo;
    }

    mapFromQuery(query: LocationQuery) {
        super.mapFromQuery(query);
        this.searchString = query.otsing ? (query.otsing as string) : "";
        this.driverGroups = query.grupp ? String(query.grupp).split(",") : [];
        this.vehicleTypes = query.soidukiliik ? String(query.soidukiliik).split(",") : [];
        this.workTypes = query.tooliik ? String(query.tooliik).split(",") : [];
        this.jobCompletionTypes = query.staatus ? String(query.staatus).split(",") : [];
        this.shiftFrom = query.alates ? moment(query.alates as string).toDate() : undefined;
        this.shiftTo = query.kuni ? moment(query.kuni as string).toDate() : undefined;
    }

    createQuery() {
        return {
            ...super.createQuery(),
            otsing: this.searchString ? this.searchString : undefined,
            grupp: this.driverGroups.length ? this.driverGroups : undefined,
            soidukiliik: this.vehicleTypes.length ? this.vehicleTypes : undefined,
            tooliik: this.workTypes.length ? this.workTypes : undefined,
            staatus: this.jobCompletionTypes.length ? this.jobCompletionTypes : undefined,
            alates: this.shiftFrom ? moment(this.shiftFrom).toISOString() : undefined,
            kuni: this.shiftTo ? moment(this.shiftTo).toISOString() : undefined,
        };
    }

    clearAllFilters(clearSearch = true): ReservesListFilters {
        sessionStorage.removeItem(ReservesListFilters.STORAGE_KEY);

        const newFilters = new ReservesListFilters();
        if (clearSearch) {
            return newFilters;
        }

        newFilters.searchString = this.searchString;
        return newFilters;
    }

    applyOn(jobs: api.IReserveJobViewModel[]): api.IReserveJobViewModel[] {
        jobs = this.filterBySearchString(jobs);
        jobs = this.filterByVehicleType(jobs);
        jobs = this.filterByDriverGroups(jobs);
        jobs = this.filterByWorkType(jobs);
        jobs = this.filterByJobCompletionType(jobs);
        jobs = this.filterByShiftTime(jobs);
        return jobs;
    }

    private filterBySearchString(jobs: api.IReserveJobViewModel[]): api.IReserveJobViewModel[] {
        return jobs.filter((job) =>
            this.searchString
                ? job.servingDriverAndVehicle.driver.name.toLowerCase().includes(this.searchString.toLowerCase()) ||
                  job.servingDriverAndVehicle.driver.tableNumber.toLowerCase().startsWith(this.searchString.toLowerCase()) ||
                  (job.servingDriverAndVehicle.vehicle?.garageNumber ?? "").toLowerCase().startsWith(this.searchString.toLowerCase())
                : true
        );
    }

    private filterByDriverGroups(jobs: api.IReserveJobViewModel[]): api.IReserveJobViewModel[] {
        return jobs.filter((job) => (this.driverGroups.length ? this.driverGroups.includes(job.servingDriverAndVehicle.driver.group ?? "") : true));
    }
    private filterByVehicleType(jobs: api.IReserveJobViewModel[]): api.IReserveJobViewModel[] {
        return jobs.filter((job) =>
            this.vehicleTypes.length
                ? this.vehicleTypes.includes(job.servingDriverAndVehicle.vehicle?.type == null ? ReservesListFilters.NoVehicle : job.servingDriverAndVehicle.vehicle.type.label())
                : true
        );
    }

    private filterByWorkType(jobs: api.IReserveJobViewModel[]): api.IReserveJobViewModel[] {
        return jobs.filter((job) => (this.workTypes.length ? this.workTypes.includes(workTypeTranslator.translate(job.servingDriverAndVehicle.workType)) : true));
    }

    private filterByJobCompletionType(jobs: api.IReserveJobViewModel[]): api.IReserveJobViewModel[] {
        return jobs.filter((job) => {
            if (this.jobCompletionTypes.length === 0) {
                return true;
            }

            if (this.jobCompletionTypes.includes("Lõppenud") && moment(job.afterShiftPreparationEnd).isBefore(moment())) {
                return true;
            }

            if (this.jobCompletionTypes.includes("Töös") && moment(job.preparationStart).isBefore(moment()) && moment(job.afterShiftPreparationEnd).isAfter(moment())) {
                return true;
            }

            if (this.jobCompletionTypes.includes("Alustab tulevikus") && moment(job.preparationStart).isAfter(moment())) {
                return true;
            }

            return false;
        });
    }

    private filterByShiftTime(jobs: api.IReserveJobViewModel[]): api.IReserveJobViewModel[] {
        return jobs
            .filter((job) => (this.shiftFrom ? moment(job.preparationStart) >= moment(this.shiftFrom) : true))
            .filter((job) => (this.shiftTo ? moment(job.afterShiftPreparationEnd) <= moment(this.shiftTo) : true));
    }
}
