import { LocationQuery, LocationQueryRaw, type RouteLocationNormalized } from "vue-router";
import { IPaginationWithSorting, ListFilters, ListFiltersWithSortedPaging } from "@/components/ListFilters";

export interface IDeparturesTableListFilters extends IPaginationWithSorting<DeparturesListColumns> {
    stops: string[];
    showDeparturesFromAllTrips: boolean;
}

export enum DeparturesListColumns {
    Väljub = "väljub",
    Juht = "juht",
    EsimenePeatus = "esimenePeatus",
    VehicleRoute = "vehicleRoute",
    Vehicle = "vehicle",
    Liin = "liin",
    Teated = "teated",
}

export class DeparturesTableListFilters extends ListFiltersWithSortedPaging<DeparturesListColumns> {
    stops: string[] = [];
    showDeparturesFromAllTrips = false;

    constructor(data?: Partial<IDeparturesTableListFilters>) {
        super(data);
        this.stops = data?.stops ?? [];
        this.showDeparturesFromAllTrips = data?.showDeparturesFromAllTrips ?? false;
    }

    mapFromQuery(query: LocationQuery) {
        super.mapFromQuery(query);
        this.stops = query.peatus ? String(query.peatus).split(",") : [];
        this.showDeparturesFromAllTrips = String(query.koik) === "true";
    }

    // Query parameter names are in estonian to match the naming style on all routes.
    createQuery() {
        return {
            ...super.createQuery(),
            peatus: this.stops.length ? this.stops : undefined,
            koik: this.showDeparturesFromAllTrips.toString(),
        };
    }

    clearAllFilters() {
        return new DeparturesTableListFilters();
    }
}
