import { IPaginationWithSorting, ListFiltersWithSortedPaging } from "@/components/ListFilters";
import { api } from "@/api-client";
import { LocationQuery } from "vue-router";

export interface IVehicleListFilters extends IPaginationWithSorting<VehicleListColumns | VehicleRoutesListColumns> {
    searchString: string | undefined;
    divisions: string[];
    types: string[];
}

export enum VehicleRoutesListColumns {
    Vehicle = "soiduk",
    RegistrationNumber = "regnr",
    Mark = "mark",
    Division = "divisjon",
    TechnicalCondition = "tehnoseisund",
    Route = "trassinr",
}

export enum VehicleListColumns {
    Vehicle = "soiduk",
    RegistrationNumber = "regnr",
    Mark = "mark",
    Division = "divisjon",
    IsPubliclyAccessible = "avalikligipaas",
}

export type VehicleListFilterOptions = {
    divisionFilterOptions: string[];
    typeFilterOptions: string[];
};

export class VehicleListFilters extends ListFiltersWithSortedPaging<VehicleListColumns | VehicleRoutesListColumns> {
    searchString: string | undefined = undefined;
    divisions: string[] = [];
    types: string[] = [];

    constructor(data?: Partial<IVehicleListFilters>) {
        super(data);
        this.searchString = data?.searchString ?? undefined;
        this.divisions = data?.divisions ?? [];
        this.types = data?.types ?? [];
    }

    mapFromQuery(query: LocationQuery) {
        super.mapFromQuery(query);
        this.searchString = query.otsing ? String(query.otsing) : undefined;
        this.divisions = query.divisjon ? String(query.divisjon).split(",") : [];
        this.types = query.soidukiliik ? String(query.soidukiliik).split(",") : [];
    }

    createQuery() {
        return {
            ...super.createQuery(),
            otsing: this.searchString?.length ? this.searchString : undefined,
            divisjon: this.divisions.length ? this.divisions : undefined,
            soidukiliik: this.types.length ? this.types : undefined,
        };
    }

    applyOn(vehicles: Array<api.VehicleAndRedirectUrlViewModel>): Array<api.VehicleAndRedirectUrlViewModel> {
        return vehicles
            .filter((vehicle) => filterBySearchString(this.searchString, vehicle.vehicle))
            .filter((vehicle) => filterByDivision(this.divisions, vehicle.vehicle))
            .filter((vehicle) => filterByType(this.types, vehicle.vehicle));

        function filterBySearchString(searchString: string | undefined, vehicle: api.VehicleViewModel): boolean {
            if (!searchString?.length) {
                return true;
            }

            const hasMatchingGarageNumber = vehicle.garageNumber != null && vehicle.garageNumber.toLowerCase().includes(searchString.toLowerCase());
            const hasMatchingRegistrationNumber = vehicle.registrationNumber != null && vehicle.registrationNumber.toLowerCase().includes(searchString.toLowerCase());
            const hasMatchingRoute = vehicle.vehicleRoute != null && vehicle.vehicleRoute.toLowerCase().includes(searchString.toLowerCase());

            return hasMatchingGarageNumber || hasMatchingRegistrationNumber || hasMatchingRoute;
        }

        function filterByDivision(divisions: string[], vehicle: api.VehicleViewModel): boolean {
            if (!divisions.length) {
                return true;
            }

            return vehicle.division != null && divisions.includes(vehicle.division);
        }

        function filterByType(types: string[], vehicle: api.VehicleViewModel): boolean {
            if (!types.length) {
                return true;
            }

            return types.includes(vehicle.type.label());
        }
    }

    clearAllFilters() {
        return new VehicleListFilters();
    }
}
